import React from "react";
import { graphql } from "gatsby";

import {
  Form,
  Field,
  Input,
  Button,
  composeValidators,
  mustBeEmail,
  required,
} from "../components/form";
import { getBlockContent } from "../utils";
import { Header } from "../components/layout/Header";
import SheetSection from "../components/layout/SheetSection";
import { H3, P, Spinner } from "../components/display";
import { Box, Ul, Li } from "../components/layout";
import ConvinceSection from "../components/layout/ConvinceSection";
import CourseSection from "../components/course/CourseSection";
import { LinkButton } from "../components/navigation/Link";
import Layout from "../components/layout/Layout";
import SEO from "../components/SEO";
import { contact } from "../api";

function CourseDetail({ data }) {
  const { course } = data;
  const headerImage =
    course?.image?.image?.asset?.localFile?.childImageSharp?.fixed;

  return (
    <Layout>
      <SEO
        imageUrl={course?.image?.image?.asset?.localFile?.publicURL}
        title={course.title}
        description={course.description}
      />
      <Header
        bgFixedImage={headerImage}
        title={course.title}
        subtitle={course.subtitle}
        bgColors={["#265199", "#265199", "transparent"]}
        bgGradientOpacity={1}
      />

      <SheetSection top={true}>
        <Box sx={{ gridColumn: ["1 / -1", "1/ 6"] }}>
          <H3>Contenido del curso</H3>
          {getBlockContent(course._rawSyllabus)}
          <H3>¿Para qué?</H3>
          {getBlockContent(course._rawObjectives)}
        </Box>
        <Box sx={{ gridColumn: ["1 / -1", "7/ -1"] }}>
          <H3>Próxima convocatoria e inscripción</H3>
          {course.addressDates || course.courseOptions || course.price ? (
            <>
              <Ul>
                {course.addressDates && (
                  <Li>Fecha y horario: {course.addressDates}</Li>
                )}
                {course.courseOptions && (
                  <Li>Opciones de curso: {course.courseOptions}</Li>
                )}
                {course.price && <Li>Precio: {course.price} €</Li>}
              </Ul>
              <P>Rellena el siguiente formulario de contacto:</P>
            </>
          ) : (
            <P>
              Deja tu nombre y correo electrónico y te informaremos en detalle
              sobre el curso.
            </P>
          )}

          <Form
            onSubmit={async (values: any) => {
              await contact({
                subject: "🎉 Me interesa este curso | ReinventaFormacion",
                message: `
                  Nombre del curso: ${course.title}<br />
                  Email de contacto: ${values.email}<br />
                  Nombre completo:  ${values.name}
                  `,
              });
            }}
          >
            {({ submitSucceeded, submitting }) =>
              submitSucceeded ? (
                <H3>
                  Mensaje enviado. Te contactaremos cuando haya nuevas
                  convocatorias.
                </H3>
              ) : (
                <>
                  <Field
                    component={Input}
                    validate={composeValidators(required)}
                    label="Nombre completo:"
                    name="name"
                    placeholder="Escribe tu nombre aquí"
                  />
                  <Field
                    component={Input}
                    validate={composeValidators(mustBeEmail, required)}
                    label="Tu correo electrónico:"
                    name="email"
                    placeholder="direccion@ejemplo.com"
                  />
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={submitting}
                    className="newsletter-submit-button"
                  >
                    {submitting ? <Spinner /> : "Enviar"}
                  </Button>
                </>
              )
            }
          </Form>
          <H3>Empresas</H3>
          <P>¿Estás interesado en una formacion a medida para tu empresa?</P>
          <LinkButton to="/formacion-para-empresas">
            Ver sección empresas
          </LinkButton>
        </Box>
      </SheetSection>

      <ConvinceSection
        testimonialFluidImage={
          course.convinceSection?.testimonial?.image?.asset?.localFile
            ?.childImageSharp?.fluid
        }
        testimonialQuote={course.convinceSection?.quote}
        testimonialFullname={course.convinceSection?.testimonial?.fullname}
        title={course.convinceSection?.title}
        text={getBlockContent(course.convinceSection?._rawText)}
      />

      <CourseSection
        showTabs={false}
        title="Cursos relacionados"
        courses={course.relatedCourses}
      />
    </Layout>
  );
}

export const query = graphql`
  query CourseTemplateQuery($id: String!) {
    course: sanityCourse(id: { eq: $id }) {
      title
      id
      subtitle
      _rawSyllabus
      _rawObjectives
      description
      courseOptions
      addressDates
      price
      relatedCourses {
        title
        slug {
          current
        }
        description
        image {
          alt
          image {
            asset {
              localFile(width: 600) {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
      convinceSection {
        subtitle
        title
        quote
        _rawText
        testimonial {
          fullname
          image {
            alt
            image {
              asset {
                localFile(width: 600) {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
          youtubeId
        }
      }
      image {
        alt
        image {
          asset {
            localFile(width: 1200) {
              publicURL
              childImageSharp {
                fixed(width: 1200) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default CourseDetail;
